// 50: '#f8fafc',
// 100: '#f1f5f9',
// 200: '#e2e8f0',

const ServiceIcon = ({shadowType, footer}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={`
        rounded-full 
        ${shadowType === "lg" ? 
          "shadow-lg" : 
          shadowType === "md" ? 
          "shadow-md" : 
          shadowType === "sm" ? 
          "shadow-sm" : 
          null}`
          } viewBox="0 0 1174.72 1174.72">
      <defs>
        {/* currently blueGray-100 */}
        <style>{`.cls-1,.cls-2{fill:#f1f5f9;}.cls-2{opacity:0.41;}`}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="cls-1" d="M426.22,583.22H764.46a0,0,0,0,1,0,0v16.25a5.12,5.12,0,0,1-5.12,5.12h-328a5.12,5.12,0,0,1-5.12-5.12V583.22A0,0,0,0,1,426.22,583.22Z"/>
          <path className="cls-1" d="M431.95,485.82H758.73a4.91,4.91,0,0,1,4.91,4.91v15.58a0,0,0,0,1,0,0H427a0,0,0,0,1,0,0V490.73A4.91,4.91,0,0,1,431.95,485.82Z" transform="translate(1190.68 992.13) rotate(180)"/>
          <path className="cls-1" d="M689.84,654.91H498.74c-12.32,0-23.42-8.11-28-20.5l-6.31-16.9H723.82l-5.71,16.27C713.64,646.5,702.39,654.91,689.84,654.91Z"/>
          <rect className="cls-1" x="482.86" y="356.11" width="169.94" height="118.45"/>
          <rect className="cls-1" x="661.02" y="356.11" width="42.69" height="118.45"/>
          <path className="cls-1" d="M723.41,343.64H464c0-30,131.36-62.34,131.36-62.34s128.07,32.34,128.07,62.34Z"/><rect className="cls-2" x="444.28" y="515.53" width="123.15" height="54.33"/>
          <rect className="cls-1" x="580.56" y="515.53" width="29.56" height="54.33"/>
          <rect className="cls-2" x="620.79" y="515.53" width="87.84" height="54.33"/>
          <rect className="cls-1" x="443.74" y="515.53" width="305.12" height="54.33"/>
          <path className="cls-1" d="M775.52,464.42a131.4,131.4,0,0,1,19.75-3.73c6.57-.78,13.13-1.25,19.68-1.45a142.93,142.93,0,0,1,19.62.75c6.52.7,13,1.54,19.58,2.06l19.59,1.67,19.58,1.88c13.06,1.34,26.11,2.66,39.16,4.26l-.05,1.46c-13.11.69-26.22,1.11-39.32,1.54l-19.66.52-19.65.3c-6.54.07-13.1.46-19.66.7a142.85,142.85,0,0,1-19.62-.61c-6.52-.65-13-1.57-19.55-2.81a133.26,133.26,0,0,1-19.49-5.09Z"/>
          <path className="cls-1" d="M761.58,382.45a91.84,91.84,0,0,1,13.64-9.05,146.49,146.49,0,0,1,14.24-6.87,90.34,90.34,0,0,1,14.82-4.75c5.06-1.16,10.15-2.18,15.17-3.51l15.06-3.88,15.12-3.68c10.11-2.37,20.21-4.75,30.38-6.87l.39,1.39c-9.57,4.32-19.21,8.37-28.84,12.44l-14.48,6-14.55,5.76c-4.85,1.89-9.62,4.09-14.43,6.15a90.9,90.9,0,0,1-14.79,4.9,140,140,0,0,1-15.37,2.78,84.48,84.48,0,0,1-16,.61Z"/>
          <path className="cls-1" d="M774.34,422.25A191.92,191.92,0,0,1,797.77,416q11.76-2.44,23.58-4a209.49,209.49,0,0,1,23.73-1.81C853,410,861,410,868.9,409.68l23.79-.9,23.8-.68c15.88-.36,31.75-.75,47.64-.85l.1,1.45c-15.74,2.4-31.5,4.51-47.25,6.65l-23.65,3.07-23.65,2.86c-7.89.93-15.76,2.13-23.63,3.25a212.05,212.05,0,0,1-23.72,2q-11.91.3-23.87-.25a189.54,189.54,0,0,1-24-2.53Z"/>
          <path className="cls-1" d="M415.2,465.87A133.47,133.47,0,0,1,395.72,471c-6.51,1.24-13,2.16-19.56,2.81a142.85,142.85,0,0,1-19.62.61c-6.56-.24-13.11-.63-19.66-.7l-19.65-.3-19.66-.52c-13.1-.43-26.21-.85-39.32-1.54l0-1.46c13-1.6,26.1-2.92,39.15-4.26l19.58-1.88,19.59-1.67c6.53-.52,13.06-1.36,19.58-2.06a142.93,142.93,0,0,1,19.62-.75c6.55.2,13.11.67,19.68,1.45a131.4,131.4,0,0,1,19.75,3.73Z"/>
          <path className="cls-1" d="M428.71,383.85a84.48,84.48,0,0,1-16-.61,140.43,140.43,0,0,1-15.37-2.78,90.9,90.9,0,0,1-14.79-4.9c-4.81-2.06-9.58-4.26-14.43-6.15l-14.55-5.76-14.48-6c-9.63-4.07-19.27-8.12-28.84-12.44l.39-1.39c10.17,2.12,20.27,4.5,30.38,6.87l15.12,3.68,15.06,3.88c5,1.33,10.11,2.35,15.17,3.51a90.76,90.76,0,0,1,14.83,4.75,147.45,147.45,0,0,1,14.23,6.87,91.33,91.33,0,0,1,13.64,9.05Z"/>
          <path className="cls-1" d="M416.24,423.7a189.54,189.54,0,0,1-24,2.53q-12,.57-23.87.25a211.68,211.68,0,0,1-23.71-2c-7.88-1.12-15.75-2.32-23.64-3.25l-23.65-2.86-23.65-3.07c-15.75-2.14-31.51-4.25-47.25-6.65l.1-1.45c15.89.1,31.77.49,47.64.85l23.8.68,23.79.9c7.93.31,15.88.36,23.82.49A209.49,209.49,0,0,1,369.33,412q11.82,1.57,23.58,4a191.92,191.92,0,0,1,23.43,6.27Z"/>
          <polygon className="cls-1" points="701.14 767.69 693.86 666.94 499.28 666.94 478.93 939.05 701.14 767.69"/>
          <polygon className="cls-1" points="721.13 1044.39 702.26 783.21 477.63 956.44 466.39 1106.72 532.72 1118.3 625.79 1117.92 721.13 1044.39"/>
          <polygon className="cls-1" points="725.72 1097.3 722.25 1059.92 635.72 1127.3 647.72 1126.3 725.72 1097.3"/>
          <path className="cls-1" d="M587.36,1143.92A556.69,556.69,0,0,1,370.72,74.55,556.69,556.69,0,0,1,804,1100.17,553,553,0,0,1,587.36,1143.92Zm0-1081.12A524.7,524.7,0,0,0,383.18,1070.7,524.7,524.7,0,0,0,791.54,104,521.26,521.26,0,0,0,587.36,62.8Z"/>
          <path className="cls-1" d="M587.36,1174.72A587.51,587.51,0,0,1,358.73,46.16,587.51,587.51,0,0,1,816,1128.56,583.7,583.7,0,0,1,587.36,1174.72ZM587.36,3A584.52,584.52,0,0,0,359.9,1125.8,584.51,584.51,0,0,0,814.82,48.92,580.71,580.71,0,0,0,587.36,3Z"/>
        </g>
      </g>
      </svg>
    )
  }

  export default ServiceIcon;